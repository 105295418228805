<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" ><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><g><g><path d="M13,19V7.83l4.88,4.88c0.39,0.39,1.03,0.39,1.42,0l0,0c0.39-0.39,0.39-1.02,0-1.41l-6.59-6.59 c-0.39-0.39-1.02-0.39-1.41,0l-6.6,6.58c-0.39,0.39-0.39,1.02,0,1.41l0,0c0.39,0.39,1.02,0.39,1.41,0L11,7.83V19 c0,0.55,0.45,1,1,1l0,0C12.55,20,13,19.55,13,19z"/></g></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconArrowUp',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
